import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import { services } from "../utils/Data";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import wishlist from "../images/wishlist.svg";
import watch from "../images/watch.jpg";
import watch2 from "../images/watch-1.avif";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { getAllProducts } from "../features/products/productSlilce";
import ReactStars from "react-rating-stars-component";
import { addToWishlist } from "../features/products/productSlilce";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const Home = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getblogs();
    getProducts();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };

  const getProducts = () => {
    dispatch(getAllProducts());
  };

  const addToWish = (id) => {
    //alert(id);
    dispatch(addToWishlist(id));
  };
  return (
    <>
<Container class1="home-wrapper-1 py-5">
  <div className="row">
    <div className="col-6">
      <div className="main-banner position-relative ">
        <img
          src="images/main-banner-1.jpg"
          className="img-fluid rounded-3"
          alt="main banner"
        />
        <div className="main-banner-content position-absolute">
          <h4>EXCEL IN YOUR UPSC PREPARATION</h4>
          <h5>The Complete UPSC Guide</h5>
          <p>Starting from Rs. 999.00</p>
          <Link className="button">BUY NOW</Link>
        </div>
      </div>
    </div>
    <div className="col-6">
      <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center">
        <div className="small-banner position-relative">
          <img
            src="images/catbanner-01.jpg"
            className="img-fluid rounded-3"
            alt="main banner"
          />
          <div className="small-banner-content position-absolute">
            <h4>TOP SELLER</h4>
            <h5>Indian Polity by M. Laxmikanth</h5>
            <p>
              From Rs. 650.00 <br />
            </p>
          </div>
        </div>
        <div className="small-banner position-relative">
          <img
            src="images/catbanner-02.jpg"
            className="img-fluid rounded-3"
            alt="main banner"
          />
          <div className="small-banner-content position-absolute">
            <h4>NEW ARRIVAL</h4>
            <h5>Environment by Shankar IAS</h5>
            <p>
              From Rs. 550.00 <br />
            </p>
          </div>
        </div>
        <div className="small-banner position-relative ">
          <img
            src="images/catbanner-03.jpg"
            className="img-fluid rounded-3"
            alt="main banner"
          />
          <div className="small-banner-content position-absolute">
            <h4>TOP CHOICE</h4>
            <h5>History by Spectrum</h5>
            <p>
              From Rs. 400.00 <br />
            </p>
          </div>
        </div>
        <div className="small-banner position-relative ">
          <img
            src="images/catbanner-04.jpg"
            className="img-fluid rounded-3"
            alt="main banner"
          />
          <div className="small-banner-content position-absolute">
            <h4>ESSENTIAL READING</h4>
            <h5>Geography by G.C. Leong</h5>
            <p>
              From Rs. 350.00 <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</Container>

      
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-between">
              {services?.map((i, j) => {
                return (
                  <div className="d-flex align-items-center gap-15" key={j}>
                    <img src={i.image} alt="services" />
                    <div>
                      <h6>{i.title}</h6>
                      <p className="mb-0">{i.tagline}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
      <Container class1="featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Featured Collection</h3>
          </div>
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "featured") {
                return (
                  <div key={index} className={"col-3"}>
                    <div className="product-card position-relative">
                      <div className="wishlist-icon position-absolute">
                        <button className="border-0 bg-transparent">
                          <img
                            src={wish}
                            alt="wishlist"
                            onClick={(e) => {
                              addToWish(item?._id);
                            }}
                          />
                        </button>
                      </div>
                      <div className="product-image">
                      <img
  src={item?.images && item.images[0]?.url ? item.images[0].url : "https://via.placeholder.com/260x250"}
  alt="product image"
  height={"250px"}
  width={"260px"}
  onClick={() => navigate("/product/" + item?._id)}
/>
                      </div>
                      <div className="product-details">
                        <h6 className="brand">{item?.brand}</h6>
                        <h5 className="product-title">
                          {item?.title?.substr(0, 70) + "..."}
                        </h5>
                        <ReactStars
  count={5}
  size={24}
  value={Number(item?.totalrating) || 0} // Ensure it's a number, default to 0 if undefined
  edit={false}
  activeColor="#ffd700"
/>


                        <p className="price">Rs. {item?.price}</p>
                      </div>
                      <div className="action-bar position-absolute">
                        <div className="d-flex flex-column gap-15">

                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Container>

      <Container class1="famous-wrapper py-5 home-wrapper-2 famous-container">
  <div className="row">
    <div className="col-3">
      <div className="famous-card position-relative">
        <img
          src="images/famous-1.jpg"
          className="img-fluid"
          alt="famous"
        />
        <div className="famous-content position-absolute">
          <h5>Government Exams</h5>
          <h6>Comprehensive Study Materials</h6>
          <p>From Rs. 399</p>
        </div>
      </div>
    </div>
    <div className="col-3">
      <div className="famous-card position-relative">
        <img
          src="images/famous-2.png"
          className="img-fluid"
          alt="famous"
        />
        <div className="famous-content position-absolute">
          <h5 className="text-dark">SSB Interview Guide</h5>
          <h6 className="text-dark">Prepare for Success</h6>
          <p className="text-dark">From Rs. 899</p>
        </div>
      </div>
    </div>
    <div className="col-3">
      <div className="famous-card position-relative">
        <img
          src="images/famous-3.png"
          className="img-fluid"
          alt="famous"
        />
        <div className="famous-content position-absolute">
          <h5 className="text-dark">CDS Exam</h5>
          <h6 className="text-dark">Crack the Defence Services Exam</h6>
          <p className="text-dark">From Rs. 1,199</p>
        </div>
      </div>
    </div>
    <div className="col-3">
      <div className="famous-card position-relative">
        <img
          src="images/famous-4.png"
          className="img-fluid"
          alt="famous"
        />
        <div className="famous-content position-absolute">
          <h5 className="text-dark">UPSC Preparation</h5>
          <h6 className="text-dark">Comprehensive Guides & Books</h6>
          <p className="text-dark">From Rs. 999</p>
        </div>
      </div>
    </div>
  </div>
</Container>



      <Container class1="special-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Special Products</h3>
          </div>
        </div>
        <div className="row">
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "special") {
                //console.log(item?._id);
                return (
                  <SpecialProduct
                    key={index}
                    id={item?._id}
                    title={item?.title}
                    brand={item?.brand}
                    totalrating={item?.totalrating.toString()}
                    price={item?.price}
                    img={item?.images[0].url}
                    sold={item?.sold}
                    quantity={item?.quantity}
                  />
                );
              }
            })}
        </div>
      </Container>
      <Container className="popular-wrapper py-5 home-wrapper-2">
      <div className="row">
        <div className="col-12">
          <h3 className="section-heading">Our Popular Products</h3>
        </div>
      </div>
      <div className="row">
        {productState &&
          productState.map((item, index) => {
            if (item.tags === "popular") {
              return (
                <div key={index} className="col-3">
                  <div className="product-card position-relative">
                    <div className="wishlist-icon position-absolute">
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => addToWish(item?._id)}
                      >
                        <img
                          src={wish}
                          alt="wishlist"
                        />
                      </button>
                    </div>
                    <div className="product-image">
                      <img
                        src={
                          item?.images && item.images[0]?.url
                            ? item.images[0].url
                            : "https://via.placeholder.com/260x250"
                        }
                        alt="product image"
                        className="product-img"
                        onClick={() => navigate("/product/" + item?._id)}
                      />
                    </div>
                    <div className="product-details">
                      <h6 className="brand">{item?.brand}</h6>
                      <h5 className="product-title">
                        {item?.title?.substr(0, 70) + "..."}
                      </h5>
                      <ReactStars
                        count={5}
                        size={24}
                        value={Number(item?.totalrating) || 0} // Ensure it's a number, default to 0 if undefined
                        edit={false}
                        activeColor="#ffd700"
                      />
                      <p className="price">Rs. {item?.price}</p>
                    </div>
                    <div className="action-bar position-absolute">
                      <div className="d-flex flex-column gap-15"></div>
                    </div>
                  </div>
                </div>
              );
            }
            return null; // Ensure we return null if the condition doesn't match
          })}
      </div>
    </Container>
      <Container class1="marque-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="marquee-inner-wrapper card-wrapper">
              <Marquee className="d-flex">
                <div className="mx-4 w-25">
                  <img src="images/brand-01.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-02.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-03.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-04.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-05.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-06.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-07.png" alt="brand" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>

      <Container class1="blog-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Our Latest Blogs</h3>
          </div>
        </div>
        <div className="row">
          {blogState &&
            blogState?.map((item, index) => {
              if (index < 4) {
                return (
                  <div className="col-3 " key={index}>
                    <BlogCard
                      id={item?._id}
                      title={item?.title}
                      description={item?.description}
                      image={item?.images[0]?.url}
                      date={moment(item?.createdAt).format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    />
                  </div>
                );
              }
            })}
        </div>
      </Container>
    </>
  );
};

export default Home;
